import React from 'react'
import PropTypes from 'prop-types'
import { Modal, Button } from 'react-bootstrap'
import Cookies from 'js-cookie'
import Actions from '../../actions/listings'
import styles from '../../styles'

const L = window.locales

class Sorts extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: this.props.open ? this.props.open : false
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.open !== prevState.open) {
      return {open: nextProps.open}
    }
    return null
  }

  close() {
    this.props.onCloseClick()
  }

  handleSortChange(sort) {
    const newSort = sort == this.props.sorts.sortOrder ? null : sort
    this.props.dispatch(Actions.changeSortUnit(newSort, this.props.sorts.units))
    this.props.page.page == -1 ? this.props.push({sortOrder: newSort == null ? 'clear' : newSort}) : this.props.push({sortOrder: newSort == null ? 'clear' : newSort, page: 1})
    this.props.onCloseClick()
  }

  handleUnitChange(unit) {
    this.props.dispatch(Actions.changeSortUnit(this.props.sorts.sortOrder, unit))
    this.props.dispatch(Actions.changeRange({size: unit == 'ft' ? [210, 1470] : [20, 140]}))
    this.props.push({unit: unit, page: 1, size: unit == 'ft' ? [210, 1470] : [20, 140]})
    Cookies.set('unit', unit)
    this.props.onCloseClick()
  }

  changeAreaFor(e) {
    this.props.push(Object.assign({}, {areaFor: e.target.value}))
    this.props.dispatch(Actions.changeAreaFor(e.target.value))
  }

  render() {
    const { areaFor, sortOrder, units } = this.props.sorts
    let sorts = null
    if (this.state.open) {
      sorts = (
        <Modal show={this.state.open} onHide={this.close.bind(this)} animation={false} className="listings_modal">
          <Modal.Header closeButton>
            <Modal.Title>{L.sort}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="modal-no-footer">
            <div className="sidebar_wrapper" style={styles.filterContainer}>
              <div className="overlay-section-header"><span className="fas fa-sort fa-fw"></span> {L.sort_by}</div>
              <div className="sort-split">
                {(this.props.target == 'rent' || this.props.target == 'monthly' || this.props.target == undefined) ?
                 <>
                   <div className="sort-by-header">{L.rent}:</div>
                   <button id="rent-asc" className={sortOrder == 'rent-asc' ? 'button-sort button-sort-active' : 'button-sort button-sort-inactive'} type="button" onClick={this.handleSortChange.bind(this, 'rent-asc')}>{L.rent} <span className="fas fa-sort-amount-down-alt fa-fw"></span></button>
                   <button id="rent-desc" className={sortOrder == 'rent-desc' ? 'button-sort button-sort-active' : 'button-sort button-sort-inactive'} type="button" onClick={this.handleSortChange.bind(this, 'rent-desc')}>{L.rent} <span className="fas fa-sort-amount-down fa-fw"></span></button>
                 </>
                 :
                 <>
                   <div className="sort-by-header">{L.price}:</div>
                   <button id="price-asc" className={sortOrder == 'price-asc' ? 'button-sort button-sort-active' : 'button-sort button-sort-inactive'} type="button" onClick={this.handleSortChange.bind(this, 'price-asc')}>{L.price} <span className="fas fa-sort-amount-down-alt fa-fw"></span></button>
                   <button id="price-desc" className={sortOrder == 'price-desc' ? 'button-sort button-sort-active' : 'button-sort button-sort-inactive'} type="button" onClick={this.handleSortChange.bind(this, 'price-desc')}>{L.price} <span className="fas fa-sort-amount-down fa-fw"></span></button>
                 </>
                }
              </div>
              <div className="sort-split">
                <div className="sort-by-header">{L.size}:</div>
                {this.props.target == 'buy' &&
                 <div className="pl-2 d-inline-block">
                   <select className="form-control form-control-sm" style={{fontSize: '0.75rem', padding: '0.15rem', height: 'calc(1.5em + 0.5rem)'}} onChange={this.changeAreaFor.bind(this)} value={areaFor}>
                     <option value="total_floor_area_sqm">{L.total_floor_area_sqm}</option>
                     <option value="building_sqm">{L.building_sqm}</option>
                     <option value="land_sqm">{L.land_sqm}</option>
                   </select>
                 </div>
                }
                <button id="area-asc" className={sortOrder == 'area-asc' ? 'button-sort button-sort-active' : 'button-sort button-sort-inactive'} type="button" onClick={this.handleSortChange.bind(this, 'area-asc')}>{L.size} <span className="fa fa-sort-amount-down-alt fa-fw"></span></button>
                <button id="area-desc" className={sortOrder == 'area-desc' ? 'button-sort button-sort-active' : 'button-sort button-sort-inactive'} type="button" onClick={this.handleSortChange.bind(this, 'area-desc')}>{L.size} <span className="fa fa-sort-amount-down fa-fw"></span></button>
              </div>
              {(this.props.target == 'rent' || this.props.target == 'monthly' || this.props.target == undefined) &&
               <div className="sort-split">
                 <div className="sort-by-header">{L.rent}/{units}<sup>2</sup>:</div>
                 <button id="rent_per-asc" className={sortOrder == 'rent_per-asc' ? 'button-sort button-sort-active' : 'button-sort button-sort-inactive'} type="button" onClick={this.handleSortChange.bind(this, 'rent_per-asc')}> {L.rent}/{units}<sup>2</sup><span className="fa fa-sort-amount-down-alt fa-fw"></span></button>
                 <button id="rent_per-desc" className={sortOrder == 'rent_per-desc' ? 'button-sort button-sort-active' : 'button-sort button-sort-inactive'} type="button" onClick={this.handleSortChange.bind(this, 'rent_per-desc')}> {L.rent}/{units}<sup>2</sup><span className="fa fa-sort-amount-down fa-fw"></span></button>
               </div>
              }
              <div className="sort-split">
                <div className="sort-by-header">{L.year_built}:</div>
                <button id="year_built-asc" className={sortOrder == 'year_built-asc' ? 'button-sort button-sort-active' : 'button-sort button-sort-inactive'} type="button" onClick={this.handleSortChange.bind(this, 'year_built-asc')}>{L.year_built}<span className="fas fa-sort-amount-down-alt fa-fw"></span></button>
                <button id="year_built-desc" className={sortOrder == 'year_built-desc' ? 'button-sort button-sort-active' : 'button-sort button-sort-inactive'} type="button" onClick={this.handleSortChange.bind(this, 'year_built-desc')}>{L.year_built}<span className="fas fa-sort-amount-down fa-fw"></span></button>
              </div>
              <div className="overlay-section-sep"></div>
              <div className="overlay-section-header"><span className="fas fa-ruler fa-fw"></span> {L.measurement_units}</div>
              <div className="units-wrap">
                <button className={units == 'm' ? 'button-units-m2 button-units button-units-active' : 'button-units-m2 button-units button-units-inactive'} type="button" onClick={this.handleUnitChange.bind(this, 'm')}>m<sup>2</sup></button>
                <button className={units == 'ft' ? 'button-units-ft2 button-units button-units-active' : 'button-units-ft2 button-units button-units-inactive'} type="button" onClick={this.handleUnitChange.bind(this, 'ft')}>ft<sup>2</sup></button>
              </div>
              <div className="overlay-section-sep"></div>
            </div>
          </Modal.Body>
        </Modal>
      )
    }
    return sorts
  }
}

Sorts.propTypes = {
  target: PropTypes.string,
  sorts: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired
}

export default Sorts
