import React from 'react'
import PropTypes from 'prop-types'
import Autosuggest from 'react-autosuggest'
import _debounce from 'lodash/debounce'
import { Modal, Button } from 'react-bootstrap'
import Actions from '../../actions/listings'
import styles from '../../styles'

const L = window.locales

function getSuggestionValue(suggestion) {
  return suggestion.name
}

function renderSuggestion(suggestion) {
  return (
    <span>{suggestion.name}</span>
  )
}

function renderSectionTitle(section) {
  return (
    <strong>{section.title}</strong>
  )
}

function getSectionSuggestions(section) {
  return section.suggestions
}

class Locations extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value: this.props.locations.area.name ? this.props.locations.area.name : '',
      open: this.props.open ? this.props.open : false,
      selected: false
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.open !== prevState.open) {
      return {open: nextProps.open}
    }
    return null
  }

  close() {
    this.props.onCloseClick()
  }

  reset(e) {
    e.preventDefault()
    this.setState({value: '', selected: false})
    this.props.push({area: {'id': null}, page: 1, bounds: {nw: null, se: null}})
    this.props.dispatch(Actions.resetLocation())
  }

  showAll() {
    this.props.push({isLoading: true, page: -1})
    this.props.onCloseClick()
  }

  onChange = (event, { newValue, method }) => {
    this.setState({value: newValue, selected: false})
  }

  onSuggestionSelected = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
    this.setState({locationsTitle: suggestionValue, selected: true})
    this.props.dispatch(Actions.changeArea(suggestion))
    this.props.push({area: suggestion, page: 1, bounds: {nw: null, se: null}})
    this.props.onCloseClick()
  }

  onSuggestionsFetchRequested = ({ value }) => {
    this.props.dispatch(Actions.searchArea(value, this.props.target))
  }

  onSuggestionsClearRequested = () => {
  }

  render() {
    const { suggestions, isLoading } = this.props.locations

    const inputProps = {
      placeholder: L.type_to_load_suggestions_placeholder,
      value: this.state.value,
      onChange: this.onChange,
      autoFocus: !window.isMobile
    }

    let status = L.type_to_load_suggestions
    if (isLoading) {
      status = L.loading
    } else if (this.state.selected) {
      status = L.select_location
    } else if (this.state.value.trim() != '') {
      const length = suggestions.reduce((acc, s) => acc + s.suggestions.length, 0)
      if (length == 0) {
        status = L.zero_matches
      } else if (length == 1) {
        status = L.one_match
      } else {
        status = L.n_matches.replace('%{n}', length)
      }
    }

    let footerButtons = (
      <Button variant="primary" className="m-auto" onClick={this.close.bind(this)}>{L.show_results}</Button>
    )
    if (!window.isMobile && this.state.selected && this.props.page && this.props.page.total_count > 24) {
      footerButtons = (
        <>
          <div className="m-auto">
            <Button variant="primary" className="" onClick={this.close.bind(this)}>{L.show_results_w_pages}</Button>
            <Button variant="success" className="ml-2" onClick={this.showAll.bind(this)}>{L.show_all_results}</Button>
          </div>
          <small className="text-sm text-muted">{L.show_all_results_may_cause}</small>
        </>
      )
    }

    let locations = null
    if (this.state.open) {
      locations = (
        <Modal show={this.state.open} onHide={this.close.bind(this)} animation={false} className="listings_modal">
          <Modal.Header closeButton>
            <Modal.Title>{L.location}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="sidebar_wrapper" style={styles.filterContainer}>
              <div className="area_status">
                {status}
              </div>
              <Autosuggest
                multiSection={true}
                suggestions={suggestions}
                focusInputOnSuggestionClick={window.isMobile}
                onSuggestionsFetchRequested={_debounce(this.onSuggestionsFetchRequested.bind(this), 100)}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                onSuggestionSelected={this.onSuggestionSelected}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                renderSectionTitle={renderSectionTitle}
                getSectionSuggestions={getSectionSuggestions}
                inputProps={inputProps} />
              <a href="#" className="float-right" onClick={this.reset.bind(this)}><span className="fa fa-eraser"></span> {L.reset_location}</a>
              <div className="location_example">
                <p>{L.e_g_}</p>
                <ul>
                  <li>{L.minato}</li>
                  <li>{L.yoyogi_shibuya_ku}</li>
                  <li>{L.akasaka_minato_ku}</li>
                  <li>{L.yamanote_line}</li>
                  <li>{L.ebisu_station}</li>
                  <li>{L.shinjuku_station}</li>
                </ul>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {footerButtons}
          </Modal.Footer>
        </Modal>
      )
    }
    return locations
  }
}

Locations.propTypes = {
  target: PropTypes.string,
  locations: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired
}

export default Locations
