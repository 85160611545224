import React from 'react'
import PropTypes from 'prop-types'

class FilterButton extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: this.props.open,
      title: this.props.title
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.open !== prevState.open) {
      return {open: nextProps.open}
    }
    return null
  }

  render() {
    return <button className={this.props.className} onClick={this.props.onClick} dangerouslySetInnerHTML={{__html: this.state.title}}></button>
  }
}

FilterButton.propTypes = {
  className: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  btnClass: PropTypes.string,
  activeButton: PropTypes.bool
}

export default FilterButton
