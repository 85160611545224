import { createStore, applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { createLogger } from 'redux-logger'
import reducers from '../reducers/listings'

export default function configureStore () {
  let middlewares = [thunkMiddleware]
  if (process.env.NODE_ENV === 'development') {
    middlewares.push(createLogger({
      level: 'info',
      collapsed: true
    }))
  }

  const createStoreWithMiddleware = applyMiddleware.apply(this, middlewares)(createStore)

  return createStoreWithMiddleware(reducers)
}
