import React from 'react'
import PropTypes from 'prop-types'
import { Slider, Rail, Handles, Tracks, Ticks } from 'react-compound-slider'
import Switch from 'rc-switch'
import { Modal, Button } from 'react-bootstrap'
import Actions from '../../actions/listings'
import styles from '../../styles'

const L = window.locales

function Handle({handle: { id, value, percent }, getHandleProps}) {
  return (
    <div style={
      Object.assign({}, styles.handleStyle, {
        left: `${percent}%`
      })} {...getHandleProps(id)}>
    </div>
  )
}

function Track({ source, target, getTrackProps }) {
  return (
    <div style={
      Object.assign({}, styles.trackStyle, {
        left: `${source.percent}%`,
        width: `${target.percent - source.percent}%`,
      })} {...getTrackProps()}>
    </div>
  )
}

function rentFormat(value) {
  return value / 1000 + 'K'
}

function priceFormat(value) {
  return value / 1000000 + 'M'
}

function Tick({ tick, count, format }) {
  return (
    <div>
      <div style={
        Object.assign({}, styles.tickStyle, {
          left: `${tick.percent}%`
        })}>
      </div>
      <div style={
        Object.assign({}, styles.tickValueStyle, {
          marginLeft: `${-(100 / count) / 2}%`,
          width: `${100 / count}%`,
          left: `${tick.percent}%`,
        })}>
        {format ?
         format(tick.value)
         :
         tick.value
        }
      </div>
    </div>
  )
}

class Filter extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: this.props.open ? this.props.open : false
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.open !== prevState.open) {
      return {open: nextProps.open}
    }
    return null
  }

  close() {
    this.props.onCloseClick()
  }

  updateRangeValues(values, e) {
    this.props.dispatch(Actions.changeRange(values))
  }

  changeRangeValues(values, e) {
    values['page'] = this.props.page.page == -1 ? -1 : 1
    values['unit'] = this.props.sorts.units
    this.props.push(Object.assign({}, values))
  }

  changeAreaFor(e) {
    this.props.push(Object.assign({}, {areaFor: e.target.value}))
    this.props.dispatch(Actions.changeAreaFor(e.target.value))
  }

  handleKindChange(kind) {
    let kinds = [...this.props.kinds]
    if (kinds.includes(kind)) {
      kinds = kinds.filter(k => k != kind)
    } else {
      kinds.push(kind)
    }
    this.props.dispatch(Actions.changeKinds(kinds))
    this.props.push(Object.assign({}, {kinds: kinds}, this.props.page.page == -1 ? {page: -1} : {page: 1}))
  }

  handleConditionChange(flag) {
    this.props.dispatch(Actions.changeAdditional(flag))
    this.props.push(Object.assign({}, this.props.additionals, flag, this.props.page.page == -1 ? {page: -1} : {page: 1}))
  }

  render() {
    const { rent, price, bedroom, size, year } = this.props.ranges
    const { areaFor, units } = this.props.sorts
    const { kinds } = this.props
    const { pet, parking, bicycle, autolock, no_japanese, no_key_money, no_deposit, higher_than_ground_floor, free_rent, furnished, available_immediately } = this.props.additionals

    let filter = null
    if (this.state.open) {
      filter = (
        <Modal show={this.state.open} onHide={this.close.bind(this)} animation={false} className="listings_modal">
          <Modal.Header closeButton>
            <Modal.Title>{L.filter}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="sidebar_wrapper" style={styles.filterContainer}>
              {(this.props.target == 'rent' || this.props.target == 'monthly' || this.props.target == undefined) &&
               <>
                 <h6 style={styles.titleHeader}>{L.rent}</h6>
                 <div>
                   <Slider rootStyle={styles.sliderStyle}
                           domain={[window.search_rent_min, window.search_rent_max]}
                           step={10000}
                           mode={1}
                           values={rent}
                           onUpdate={rent => this.updateRangeValues({rent})}
                           onChange={rent => this.changeRangeValues({rent})}>
                     <Rail>
                       {({ getRailProps }) => (
                         <div style={styles.railStyle} {...getRailProps()} />
                       )}
                     </Rail>
                     <Handles>
                       {({ handles, getHandleProps }) => (
                         <div className="slider-handles">
                           {handles.map(handle => (
                             <Handle key={handle.id} handle={handle} getHandleProps={getHandleProps} />
                           ))}
                         </div>
                       )}
                     </Handles>
                     <Tracks left={false} right={false}>
                       {({ tracks, getTrackProps }) => (
                         <div className="slider-tracks">
                           {tracks.map(({ id, source, target }) => (
                             <Track key={id} source={source} target={target} getTrackProps={getTrackProps} />
                           ))}
                           <span style={{ fontSize: 14, position: 'absolute', top: -20, right: 0 }}>
                             {window.currencySign} {window.number_format(parseInt(rent[0] / window.rate))} ~ {(rent[1] == window.search_rent_max ? '' : window.number_format(parseInt(rent[1] / window.rate)))}
                           </span>
                         </div>
                       )}
                     </Tracks>
                     {window.currency == 'JPY' &&
                      <Ticks values={[200000, 400000, 600000, 800000, 1000000]}>
                        {({ ticks }) => (
                          <div className="slider-ticks">
                            {ticks.map(tick => (
                              <Tick key={tick.id} tick={tick} count={ticks.length} format={rentFormat} />
                            ))}
                          </div>
                        )}
                      </Ticks>
                     }
                   </Slider>
                 </div>
               </>
              }
              {this.props.target == 'buy' &&
               <>
                 <h6 style={styles.titleHeader}>{L.price}</h6>
                 <div>
                   <Slider rootStyle={styles.sliderStyle}
                           domain={[window.search_price_min, window.search_price_max]}
                           step={10000000}
                           mode={1}
                           values={price}
                           onUpdate={price => this.updateRangeValues({price})}
                           onChange={price => this.changeRangeValues({price})}>
                     <Rail>
                       {({ getRailProps }) => (
                         <div style={styles.railStyle} {...getRailProps()} />
                       )}
                     </Rail>
                     <Handles>
                       {({ handles, getHandleProps }) => (
                         <div className="slider-handles">
                           {handles.map(handle => (
                             <Handle key={handle.id} handle={handle} getHandleProps={getHandleProps} />
                           ))}
                         </div>
                       )}
                     </Handles>
                     <Tracks left={false} right={false}>
                       {({ tracks, getTrackProps }) => (
                         <div className="slider-tracks">
                           {tracks.map(({ id, source, target }) => (
                             <Track key={id} source={source} target={target} getTrackProps={getTrackProps} />
                           ))}
                           <span style={{ fontSize: 14, position: 'absolute', top: -20, right: 0 }}>
                             {window.currencySign} {window.number_format(parseInt(price[0] / window.rate))} ~ {(price[1] == window.search_price_max ? '' : window.number_format(parseInt(price[1] / window.rate)))}
                           </span>
                         </div>
                       )}
                     </Tracks>
                     {window.currency == 'JPY' &&
                      <Ticks values={[200000000, 400000000, 600000000, 800000000, 1000000000]}>
                        {({ ticks }) => (
                          <div className="slider-ticks">
                            {ticks.map(tick => (
                              <Tick key={tick.id} tick={tick} count={ticks.length} format={priceFormat} />
                            ))}
                          </div>
                        )}
                      </Ticks>
                     }
                   </Slider>
                 </div>
               </>
              }
              <h6 style={styles.titleHeader}>{L.bedroom}</h6>
              <div>
                <Slider rootStyle={styles.sliderStyle}
                        domain={[0, 5]}
                        step={1}
                        mode={1}
                        values={bedroom}
                        onUpdate={bedroom => this.updateRangeValues({bedroom})}
                        onChange={bedroom => this.changeRangeValues({bedroom})}>
                  <Rail>
                    {({ getRailProps }) => (
                      <div style={styles.railStyle} {...getRailProps()} />
                    )}
                  </Rail>
                  <Handles>
                    {({ handles, getHandleProps }) => (
                      <div className="slider-handles">
                        {handles.map(handle => (
                          <Handle key={handle.id} handle={handle} getHandleProps={getHandleProps} />
                        ))}
                      </div>
                    )}
                  </Handles>
                  <Tracks left={false} right={false}>
                    {({ tracks, getTrackProps }) => (
                      <div className="slider-tracks">
                        {tracks.map(({ id, source, target }) => (
                          <Track key={id} source={source} target={target} getTrackProps={getTrackProps} />
                        ))}
                        <span style={{ fontSize: 14, position: 'absolute', top: -20, right: 0 }}>
                          <i className="fas fa-bed"></i> {bedroom[0] == 0 ? 'Studio' : bedroom[0]}{bedroom[0] != bedroom[1] ? ' ~ ' + (bedroom[1] != 5 ? bedroom[1] : '') : ''}
                        </span>
                      </div>
                    )}
                  </Tracks>
                  <Ticks values={[1, 2, 3, 4]}>
                    {({ ticks }) => (
                      <div className="slider-ticks">
                        {ticks.map(tick => (
                          <Tick key={tick.id} tick={tick} count={ticks.length} />
                        ))}
                      </div>
                    )}
                  </Ticks>
                </Slider>
              </div>
              <h6 style={styles.titleHeader}>
                {L.size}
                {this.props.target == 'buy' &&
                 <div className="pl-2 d-inline-block">
                   <select className="form-control form-control-sm" style={{fontSize: '0.75rem', padding: '0.15rem', height: 'calc(1.5em + 0.5rem)'}} onChange={this.changeAreaFor.bind(this)} value={areaFor}>
                     <option value="total_floor_area_sqm">{L.total_floor_area_sqm}</option>
                     <option value="building_sqm">{L.building_sqm}</option>
                     <option value="land_sqm">{L.land_sqm}</option>
                   </select>
                 </div>
                }
              </h6>
              <div>
                <Slider rootStyle={styles.sliderStyle}
                        domain={units == 'ft' ? [210, 1470] : [window.search_area_min, window.search_area_max]}
                        step={units == 'ft' ? 105 : 10}
                        mode={1}
                        values={size}
                        onUpdate={size => this.updateRangeValues({size})}
                        onChange={size => this.changeRangeValues({size})}>
                  <Rail>
                    {({ getRailProps }) => (
                      <div style={styles.railStyle} {...getRailProps()} />
                    )}
                  </Rail>
                  <Handles>
                    {({ handles, getHandleProps }) => (
                      <div className="slider-handles">
                        {handles.map(handle => (
                          <Handle key={handle.id} handle={handle} getHandleProps={getHandleProps} />
                        ))}
                      </div>
                    )}
                  </Handles>
                  <Tracks left={false} right={false}>
                    {({ tracks, getTrackProps }) => (
                      <div className="slider-tracks">
                        {tracks.map(({ id, source, target }) => (
                          <Track key={id} source={source} target={target} getTrackProps={getTrackProps} />
                        ))}
                        <span style={{ fontSize: 14, position: 'absolute', top: -20, right: 0 }}>
                          <i className="fas fa-ruler-combined"></i> {`${size[0]}sq${units} ~ ` + (((size[1] != window.search_area_max) && (size[1] != 1470)) ? `${size[1]}sq${units}` : '')}
                        </span>
                      </div>
                    )}
                  </Tracks>
                  <Ticks values={units == 'ft' ? [210, 315, 420, 525, 630, 735, 840, 945, 1050, 1155, 1260, 1365, 1470] : [20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120, 130, 140]}>
                    {({ ticks }) => (
                      <div className="slider-ticks">
                        {ticks.map(tick => (
                          <Tick key={tick.id} tick={tick} count={ticks.length} />
                        ))}
                      </div>
                    )}
                  </Ticks>
                </Slider>
              </div>
              <h6 style={styles.titleHeader}>{L.year_built}</h6>
              <div>
                <Slider rootStyle={styles.sliderStyle}
                        domain={[window.search_year_min, window.search_year_max]}
                        step={1}
                        mode={1}
                        values={year}
                        onUpdate={year => this.updateRangeValues({year})}
                        onChange={year => this.changeRangeValues({year})}>
                  <Rail>
                    {({ getRailProps }) => (
                      <div style={styles.railStyle} {...getRailProps()} />
                    )}
                  </Rail>
                  <Handles>
                    {({ handles, getHandleProps }) => (
                      <div className="slider-handles">
                        {handles.map(handle => (
                          <Handle key={handle.id} handle={handle} getHandleProps={getHandleProps} />
                        ))}
                      </div>
                    )}
                  </Handles>
                  <Tracks left={false} right={false}>
                    {({ tracks, getTrackProps }) => (
                      <div className="slider-tracks">
                        {tracks.map(({ id, source, target }) => (
                          <Track key={id} source={source} target={target} getTrackProps={getTrackProps} />
                        ))}
                        <span style={{ fontSize: 14, position: 'absolute', top: -20, right: 0 }}>
                          {(year[0] != window.search_year_min || year[1] != window.search_year_max) ?
                           <>
                             <i className="fas fa-calendar-alt"></i> {year[0] == window.search_year_min ? '' : year[0]} ~ {year[1] == window.search_year_max ? '' : year[1]}
                           </>
                           :
                           <i className="fas fa-calendar-alt"></i>
                          }
                        </span>
                      </div>
                    )}
                  </Tracks>
                  <Ticks values={[1980, 1990, 2000, 2010, 2020]}>
                    {({ ticks }) => (
                      <div className="slider-ticks">
                        {ticks.map(tick => (
                          <Tick key={tick.id} tick={tick} count={ticks.length} />
                        ))}
                      </div>
                    )}
                  </Ticks>
                </Slider>
              </div>
            </div>
            <hr />
            {(this.props.target == 'rent' || this.props.target == undefined) &&
              <div className="sidebar_wrapper search_filters" style={styles.filterContainer}>
                <div className="d-flex flex-column">
                  <div className="form-check pr-3">
                    <label><input type="checkbox" className="form-check-input mark-check" id="check-apartment" name="kinds" checked={kinds.includes('apartment')} onChange={this.handleKindChange.bind(this, 'apartment')} /><span className="mark"></span> <span className="text">{window.typesMap['apartment']}</span></label>
                  </div>
                  <div className="form-check pr-3">
                    <label><input type="checkbox" className="form-check-input mark-check" id="check-house" name="kinds" checked={kinds.includes('house')} onChange={this.handleKindChange.bind(this, 'house')} /><span className="mark"></span> <span className="text">{window.typesMap['house']}</span></label>
                  </div>
                </div>
                <hr />
                <ul className="additionals" style={{paddingInlineStart: '1rem'}}>
                  <li style={styles.additionalItem} onClick={this.handleConditionChange.bind(this, {no_japanese: no_japanese == 'on' ? 'off' : 'on'})}>
                    <Switch checked={no_japanese == 'on'} onChange={this.handleConditionChange.bind(this, {no_japanese: no_japanese == 'on' ? 'off' : 'on'})} />
                    <span className="fas fa-fw fa-language"></span> {window.additionalsMap['no_japanese']}
                  </li>
                  <li style={styles.additionalItem} onClick={this.handleConditionChange.bind(this, {pet: pet == 'on' ? 'off' : 'on'})}>
                    <Switch checked={pet == 'on'} onChange={this.handleConditionChange.bind(this, {pet: pet == 'on' ? 'off' : 'on'})} />
                    <span className="fas fa-fw fa-paw"></span> {window.additionalsMap['pet']}
                  </li>
                  <li style={styles.additionalItem} onClick={this.handleConditionChange.bind(this, {no_key_money: no_key_money == 'on' ? 'off' : 'on'})}>
                    <Switch checked={no_key_money == 'on'} onChange={this.handleConditionChange.bind(this, {no_key_money: no_key_money == 'on' ? 'off' : 'on'})} />
                    <span className="fas fa-handshake-slash fa-fw"></span> {window.additionalsMap['no_key_money']}
                  </li>
                  <li style={styles.additionalItem} onClick={this.handleConditionChange.bind(this, {no_deposit: no_deposit == 'on' ? 'off' : 'on'})}>
                    <Switch checked={no_deposit == 'on'} onChange={this.handleConditionChange.bind(this, {no_deposit: no_deposit == 'on' ? 'off' : 'on'})} />
                    <span className="fas fa-handshake-alt-slash fa-fw"></span> {window.additionalsMap['no_deposit']}
                  </li>
                  <li style={styles.additionalItem} onClick={this.handleConditionChange.bind(this, {free_rent: free_rent == 'on' ? 'off' : 'on'})}>
                    <Switch checked={free_rent == 'on'} onChange={this.handleConditionChange.bind(this, {free_rent: free_rent == 'on' ? 'off' : 'on'})} />
                    <span className="fab fa-creative-commons-zero fa-fw"></span> {window.additionalsMap['free_rent']}
                  </li>
                  <li style={styles.additionalItem} onClick={this.handleConditionChange.bind(this, {parking: parking == 'on' ? 'off' : 'on'})}>
                    <Switch checked={parking == 'on'} onChange={this.handleConditionChange.bind(this, {parking: parking == 'on' ? 'off' : 'on'})} />
                    <span className="fas fa-fw fa-car"></span> {window.additionalsMap['parking']}
                  </li>
                  <li style={styles.additionalItem} onClick={this.handleConditionChange.bind(this, {bicycle: bicycle == 'on' ? 'off' : 'on'})}>
                    <Switch checked={bicycle == 'on'} onChange={this.handleConditionChange.bind(this, {bicycle: bicycle == 'on' ? 'off' : 'on'})} />
                    <span className="fas fa-fw fa-bicycle"></span> {window.additionalsMap['bicycle']}
                  </li>
                  <li style={styles.additionalItem} onClick={this.handleConditionChange.bind(this, {autolock: autolock == 'on' ? 'off' : 'on'})}>
                    <Switch checked={autolock == 'on'} onChange={this.handleConditionChange.bind(this, {autolock: autolock == 'on' ? 'off' : 'on'})} />
                    <span className="fas fa-fw fa-lock"></span> {window.additionalsMap['autolock']}
                  </li>
                  <li style={styles.additionalItem} onClick={this.handleConditionChange.bind(this, {higher_than_ground_floor: higher_than_ground_floor == 'on' ? 'off' : 'on'})}>
                    <Switch checked={higher_than_ground_floor == 'on'} onChange={this.handleConditionChange.bind(this, {higher_than_ground_floor: higher_than_ground_floor == 'on' ? 'off' : 'on'})} />
                    <span className="fas fa-fw fa-arrow-up"></span> {window.additionalsMap['higher_than_ground_floor']}
                  </li>
                  <li style={styles.additionalItem} onClick={this.handleConditionChange.bind(this, {furnished: furnished == 'on' ? 'off' : 'on'})}>
                    <Switch checked={furnished == 'on'} onChange={this.handleConditionChange.bind(this, {furnished: furnished == 'on' ? 'off' : 'on'})} />
                    <span className="fas fa-couch fa-fw"></span> {window.additionalsMap['furnished']}
                  </li>
                  <li style={styles.additionalItem} onClick={this.handleConditionChange.bind(this, {available_immediately: available_immediately == 'on' ? 'off' : 'on'})}>
                    <Switch checked={available_immediately == 'on'} onChange={this.handleConditionChange.bind(this, {available_immediately: available_immediately == 'on' ? 'off' : 'on'})} />
                    <span className="far fa-calendar-check fa-fw"></span> {window.additionalsMap['available_immediately']}
                  </li>
                </ul>
              </div>
            }
            {(this.props.target == 'buy') &&
              <div className="sidebar_wrapper search_filters" style={styles.filterContainer}>
                <div className="d-flex flex-column">
                  {Object.keys(window.typesMap).map(key => (
                    <div key={`check-${key}`} className="form-check pr-3">
                      <label><input type="checkbox" className="form-check-input mark-check" id={`check-${key}`} name="kinds" checked={kinds.includes(key)} onChange={this.handleKindChange.bind(this, key)} /><span className="mark"></span> <span className="text">{window.typesMap[key]}</span></label>
                    </div>
                  ))}
                </div>
                <hr />
                <ul className="additionals" style={{paddingInlineStart: '1rem'}}>
                  <li style={styles.additionalItem} onClick={this.handleConditionChange.bind(this, {pet: pet == 'on' ? 'off' : 'on'})}>
                    <Switch checked={pet == 'on'} onChange={this.handleConditionChange.bind(this, {pet: pet == 'on' ? 'off' : 'on'})} />
                    <span className="fas fa-fw fa-paw"></span> {window.additionalsMap['pet']}
                  </li>
                  <li style={styles.additionalItem} onClick={this.handleConditionChange.bind(this, {parking: parking == 'on' ? 'off' : 'on'})}>
                    <Switch checked={parking == 'on'} onChange={this.handleConditionChange.bind(this, {parking: parking == 'on' ? 'off' : 'on'})} />
                    <span className="fas fa-fw fa-car"></span> {window.additionalsMap['parking']}
                  </li>
                  <li style={styles.additionalItem} onClick={this.handleConditionChange.bind(this, {autolock: autolock == 'on' ? 'off' : 'on'})}>
                    <Switch checked={autolock == 'on'} onChange={this.handleConditionChange.bind(this, {autolock: autolock == 'on' ? 'off' : 'on'})} />
                    <span className="fas fa-fw fa-lock"></span> {window.additionalsMap['autolock']}
                  </li>
                </ul>
              </div>
            }
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" className="m-auto" onClick={this.close.bind(this)}>{L.show_results}</Button>
          </Modal.Footer>
        </Modal>
      )
    }
    return filter
  }
}

Filter.propTypes = {
  target: PropTypes.string,
  ranges: PropTypes.object.isRequired,
  sorts: PropTypes.object.isRequired,
  kinds: PropTypes.array,
  additionals: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired
}

export default Filter
