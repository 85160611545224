import { fitBounds } from 'google-map-react'
import { httpGet, httpPost } from '../utils'

const propertiesFitBounds = (properties) => {
  const map = document.getElementById('map-div')
  if (!map) {
    return { center: null, zoom: null }
  }
  if (properties.length == 1) {
    return { center: { lat: parseFloat(properties[0].lat), lng: parseFloat(properties[0].lng) } }
  }

  let nw = {
    lat: parseFloat(properties[0].lat),
    lng: parseFloat(properties[0].lng)
  }, se = {
    lat: parseFloat(properties[0].lat),
    lng: parseFloat(properties[0].lng)
  }
  properties.forEach((property) => {
    if (property.lat > nw.lat) {
      nw.lat = parseFloat(property.lat)
    }
    if (property.lng < nw.lng) {
      nw.lng = parseFloat(property.lng)
    }
    if (property.lat < se.lat) {
      se.lat = parseFloat(property.lat)
    }
    if (property.lng > se.lng) {
      se.lng = parseFloat(property.lng)
    }
  })

  if (nw.lat == se.lat && nw.lng == nw.lng) {
    return { center: { lat: nw.lat, lng: se.lng } }
  }

  const bounds = {
    nw: nw,
    se: se
  }
  const size = {
    width: map.clientWidth,
    height: map.clientHeight
  }

  return fitBounds(bounds, size)
}

const decorateMenu = (target) => {
  if (target == 'buy') {
    $('#under-rent').css('border-bottom', 'unset').css('padding-bottom', 'unset')
    $('#under-buy-and-sell').css('border-bottom', '3px solid rgba(255, 255, 255, 0.3)').css('padding-bottom', '0.1rem')
    $('#rent_search').removeClass('active')
    $('#monthly_search').removeClass('active')
    $('#buy_search').addClass('active')
  } else {
    $('#under-rent').css('border-bottom', '3px solid rgba(255, 255, 255, 0.3)').css('padding-bottom', '0.1rem')
    $('#under-buy-and-sell').css('border-bottom', 'unset').css('padding-bottom', 'unset')
    $('#buy_search').removeClass('active')
    if (target == 'monthly') {
      $('#rent_search').removeClass('active')
      $('#monthly_search').addClass('active')
    } else {
      $('#monthly_search').removeClass('active')
      $('#rent_search').addClass('active')
    }
  }
}

const Actions = {
  search(params, fitBounds = true, coordinates = null) {
    let target = 'rent'
    let path = '/api/properties.json'
    if (params.get('buy') == 'on') {
      target = 'buy'
      path = '/api/buy_properties.json'
    } else if (params.get('monthly') == 'on') {
      target = 'monthly'
    }
    if (window.location.href.includes('/search')) {
      decorateMenu(target)
      window.scroll({top: 0, left: 0, behavior: 'smooth' })
    }
    return dispatch => {
      const page = params.get('page')
      dispatch({
        type: 'LOCATION',
        payload: {
          loading: true
        }
      })
      dispatch({
        type: 'BEGIN_SEARCH',
        payload: {
          empty: page == 1 || page == -1 || (coordinates != null)
        }
      })
      let postParams = {}
      if (coordinates != null) {
        postParams['coordinates'] = coordinates
        dispatch({
          type: 'SET_COORDINATES',
          payload: {
            coordinates
          }
        })
      }
      const url = `${path}?${params.toString()}`
      httpPost(url, postParams).then(data => {
        dispatch({
          type: 'CHANGE_PAGE',
          payload: {
            target: target,
            page: data.page
          }
        })
        if (window.location.href.includes('/search')) {
          dispatch({
            type: 'SEARCH_RESULTS',
            payload: {
              target: target,
              properties: data.properties
            }
          })
          if (data.properties.length > 1) {
            let map = data.map
            if (fitBounds) {
              map = propertiesFitBounds(data.properties)
              if (map.zoom >= window.showAllZoomLevel) {
                map.zoom = window.showAllZoomLevel - 1
              }
              dispatch({
                type: 'CHANGE_MAP',
                payload: {
                  map: map
                }
              })
            }
          }
          if (typeof window.bookmarkButtons == 'function') {
            if (target == 'rent') {
              window.bookmarkButtons(true)
            } else if (target == 'buy') {
              window.bookmarkButtons()
            }
          }
        }
        dispatch({
          type: 'LOADING',
          payload: {
            loading: false
          }
        })
      })
    }
  },

  changeTarget(target) {
    return dispatch => {
      dispatch({
        type: 'CHANGE_TARGET',
        payload: {
          target
        }
      })
    }
  },

  changeMoveInOut(moveIn, moveOut) {
    return dispatch => {
      dispatch({
        type: 'CHANGE_MOVE_IN_OUT',
        payload: {
          moveIn,
          moveOut
        }
      })
    }
  },

  searchArea(word, target = 'rent') {
    return dispatch => {
      dispatch({
        type: 'SEARCH_AREA',
        payload: {
          value: word
        }
      })
      if (word == null) {
        dispatch({
          type: 'SELECT_AREAS',
          payload: {
            suggestions: []
          }
        })
      } else {
        const url = `/api/areas.json?q=${word}&t=${target}`
        httpGet(url).then((data) => {
          dispatch({
            type: 'SELECT_AREAS',
            payload: {
              suggestions: data
            }
          })
        })
      }
    }
  },

  changeArea(area) {
    return dispatch => {
      dispatch({
        type: 'CHANGE_AREA',
        payload: {
          area
        }
      })
      if (area.latitude && area.longitude) {
        dispatch({
          type: 'CHANGE_MAP',
          payload: {
            map: { center: { lat: parseFloat(area.latitude), lng: parseFloat(area.longitude) } }
          }
        })
      }
    }
  },

  resetLocation() {
    return dispatch => {
      dispatch({
        type: 'RESET_LOCATION',
        payload: {
        }
      })
    }
  },

  changeRange(range) {
    return dispatch => {
      dispatch({
        type: 'CHANGE_RANGE',
        payload: {
          ranges: range
        }
      })
    }
  },

  changeKinds(kinds) {
    return dispatch => {
      dispatch({
        type: 'CHANGE_KINDS',
        payload: {
          kinds: kinds
        }
      })
    }
  },

  changeAdditional(additional) {
    return dispatch => {
      dispatch({
        type: 'CHANGE_ADDITIONAL',
        payload: {
          additionals: additional
        }
      })
    }
  },

  changeSortUnit(sort, unit) {
    return dispatch => {
      dispatch({
        type: 'CHANGE_SORT_UNIT',
        payload: {
          sorts: { sortOrder: sort, units: unit }
        }
      })
    }
  },

  changeAreaFor(areaFor) {
    return dispatch => {
      dispatch({
        type: 'CHANGE_AREA_FOR',
        payload: {
          sorts: { areaFor: areaFor }
        }
      })
    }
  },

  toggleMap(display) {
    return dispatch => {
      dispatch({
        type: 'CHANGE_MAP',
        payload: {
          map: { display }
        }
      })
    }
  },

  changeMapCenter(center) {
    return dispatch => {
      dispatch({
        type: 'CHANGE_MAP',
        payload: {
          map: { center }
        }
      })
    }
  },

  selectMapPin(selectPropertyId) {
    return dispatch => {
      dispatch({
        type: 'CHANGE_MAP',
        payload: {
          map: { selectPropertyId }
        }
      })
    }
  },

  hoverMapPin(hoverPropertyId) {
    return dispatch => {
      dispatch({
        type: 'CHANGE_MAP',
        payload: {
          map: { hoverPropertyId }
        }
      })
    }
  },

  adjustMap(properties) {
    return dispatch => {
      if (properties.length > 1) {
        const map = propertiesFitBounds(properties)
        dispatch({
          type: 'CHANGE_MAP',
          payload: {
            map: map
          }
        })
      }
    }
  }
}

export default Actions
