import React from 'react'
import { connect } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import TopCompoment from '../components/top'

class Top extends React.Component {
  render() {
    return (
      <Router>
        <TopCompoment
          dispatch={this.props.dispatch}
          loading={this.props.loading}
          page={this.props.page}
          buyPage={this.props.buyPage}
          monthlyPage={this.props.monthlyPage}
          target={this.props.target}
          locations={this.props.locations}
          ranges={this.props.ranges}
          kinds={this.props.kinds}
          additionals={this.props.additionals}
          sorts={this.props.sorts}
        />
      </Router>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.loading,
    page: state.page,
    buyPage: state.buyPage,
    monthlyPage: state.monthlyPage,
    target: state.target,
    locations: state.locations,
    ranges: state.ranges,
    kinds: state.kinds,
    additionals: state.additionals,
    sorts: state.sorts
  }
}

export default connect(mapStateToProps)(Top)
