import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import 'es6-promise/auto'

import configureStore from '../store/app'
import Top from '../containers/top'

const store = configureStore()

if (document.getElementById('top')) {
  ReactDOM.render(
    <Provider store={store}>
      <Top />
    </Provider>,
    document.getElementById('top')
  )
}
